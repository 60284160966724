<template>
  <v-sheet  class="fill-height d-flex justify-center"
            :class="[
                { 'align-center' : !element.config.valign || element.config.valign === 'center'  },
                { 'align-baseline' :  element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
            color="transparent"
  >
    <div>

      content :: {{ placeholderContent }}


      <div v-for="(item , i) in content" :key="i">
        <v-divider :style="element.config.thickness ? `border-width : ${element.config.thickness}px` : ''" />

        <v-row no-gutters>
          <v-col
              :cols="MOBILE_VIEW ? 12 : 6"
              :class="!MOBILE_VIEW ? 'py-6' : 'pt-6'"
          >
            <h4 v-html="parseNewLine(item.title)" />
          </v-col>
          <v-col :cols="MOBILE_VIEW ? 12 : 6"
                 :class="!MOBILE_VIEW ? 'py-6' : 'pb-6'"
          >
            <h5 class="font-weight-regular" v-html="parseNewLine(item.text)" />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementLineEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      element : { config : {}},
      courseContent : [],
      placeholderContent : [
        { title : 'Module 1: Introduction to Design' , topics : [
            { title : 'Chapter 1: What is Design? ' , components : [
                { title : 'Lecture 1: Definition and Scope of Design' },
                { title : 'Lecture 2: Historical Overview of Design' },
                { title : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { title : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { title : 'Lecture 1: Design in Graphic Industry' },
                { title : 'Lecture 2: Design iun Product Design Industry' },
                { title : 'Lecture 3: Design in UX/UI Design Industry' }
              ]
            }
          ]
        },
        { title : 'Module 2: Introduction to Design' , topics : [
            { title : 'Chapter 1: What is Design? ' , components : [
                { title : 'Lecture 1: Definition and Scope of Design' },
                { title : 'Lecture 2: Historical Overview of Design' },
                { title : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { title : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { title : 'Lecture 1: Design in Graphic Industry' },
                { title : 'Lecture 2: Design iun Product Design Industry' },
                { title : 'Lecture 3: Design in UX/UI Design Industry' }
              ]
            }
          ]
        }

      ]
    }
  },
  computed : {
    content() {
      return this.element.config.course ? this.courseContent : this.placeholderContent
    },
    gridIconsRows() {
      switch (this.element.config.grid_icons_rows) {
        case 2 : return 6
        default : return 12
      }
    },
    gridNumbersRows() {
      switch (this.element.config.grid_numbers_rows) {
        case 1 : return 12
        case 2 : return 6
        case 3 : return 4
        case 4 : return 3
        default : return 6
      }
    },
    elementStyle() {

      let style = ''
      if ( this.element.config.thickness ) {
        style +=  `border-width: ${this.element.config.thickness || 1}px;`
      }


      if ( this.element.config.background_color ) {
       style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      if ( this.blockStyle ) {
        style += `border-color : ${this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            'line_color'
        ) } ;`

      } else {
        style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }


      return style

    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),
    parseNewLine(input) {
      if ( !input ) {
        return ''
      }
      return input.replace(/\n/g,'<br>')
    }
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>